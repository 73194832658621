<template>
  <validation-provider
    class="setting-item s-min-max"
    rules="numeric|min_value:1"
    :name="`«${localSetting.name}»`"
    v-slot="v"
    tag="div"
  >
    <esmp-input
      v-model="localSetting.value"
      :label="localSetting.name"
      :error-message="v.errors[0] || ''"
      :options="{ type: 'number', min: 1 }"
    />
  </validation-provider>
</template>

<script>
export default {
  name: 'SMinMax',
  model: {
    prop: 'setting',
    event: 'input',
  },
  props: {
    setting: {
      type: Object,
      required: true,
    },
  },
  computed: {
    localSetting: {
      get() {
        return this.setting;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>
